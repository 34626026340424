.common_container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: .77;
}


#common_header_items {
  height: 100%;
}

.common_header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.common_header h1 {
  font-size: clamp(1.5rem, 6vw, 3rem);
  font-weight: 700;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .common_img_div {
    display: none !important;
  }
}
