.contact-form {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.contact-form form .form-control {
  background-color: #f3f3f3;
  border: none;
  height: 60px;
  border: 1px solid #f3f3f3;
  -webkit-transition: .5s;
  transition: .5s;
  font-size: 16px;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact-form form textarea.form-control {
  height: auto;
  padding-top: 15px;
}

.contact-form form .form-control {
  background-color: #f3f3f3 !important;
  border: none;
  height: 60px;
  border: 1px solid #f3f3f3;
  -webkit-transition: .5s;
  transition: .5s;
  font-size: 16px;
}

.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  text-transform: capitalize;
  background-color: #207ef7;
  color: #fff;
  -webkit-transition: .5s;
  transition: .5s;
  border-radius: 30px;
  opacity: 1;
  font-weight: 400;
  font-size: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-bottom: 50px;
}

.default-btn:hover {
  background-color: var(--secondary-color);
  color: #fff;
}
