#common_section {
    align-items: center;
    padding: 100px 0;
    padding-top: 50px;
}

.common_section > .common_img > img {
    width: 100%;
    height: auto;
}

.common_content > h4 {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
}

.common_content > h1 {
    font-size: clamp(1rem, 4vw, 2rem);
    font-weight: 700;
}

.common_content > p {
    margin: 10px 0 25px 0;
    color: #7b7c93;
}

/* @media screen and (max-width: 768px) {
  .common_content {
    display: none !important;
  }
} */
