.solution_container {
    background-color: #fff;
}

.solutions_features {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 25px;
    margin-left: -10px;
    margin-right: -10px;
}

.solutions_features li {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.solutions_features li span {
    display: block;
    background-color: #f2f6fe;
    border-radius: 5px;
    padding: 20px 20px 20px 60px;
    z-index: 1;
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #0f123b;
    width: 90%;
    font-weight: 500;
    font-size: 18px;
}

.solutions_features li span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    border-radius: 5px;
    background-color: #207ef7;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.solutions_features li span::after {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    background-color: #2a2b7c;
    left: 30px;
    top: 22px;
    border-radius: 2px;
}
