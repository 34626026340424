.Common_container {
    background-color: #fff;
}

.about_items>div {
    margin-bottom: 15px;
}

.about_item {
    display: flex;
    align-items: center;
    padding: 25px 15px;
    background-color: #f0f3fd;
    border-radius: 5px;
    transition: all 0.5s ease;
    cursor: default;
}

.about_item:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;

}

.about_item>img {
    width: 28px;
    margin-right: 10px;
}

.about_item>h5 {
    margin: 0;
}
