#service_header_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service_header {
  text-align: center;
  color: #fff;
  margin-bottom: 40px;
  max-width: 650px;
}

.service_header h5 {
  color: var(--primary-color);
}
