.not_found {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
}

.notfound-img {
    width: 50%;
}

.not_found button a {
    text-decoration: none;
    color: #fff;
}
