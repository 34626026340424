@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --primary-color: #207ef7;
    --secondary-color: #07092b;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header {
    height: 80px;
    background-color: transparent;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.header.active {
    background-color: #ededed;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.header_logo_div {
    max-width: 130px;
    width: 100%;
}

.nav_menu {
    display: flex;
    list-style: none;
    margin-bottom: 0px;
    background-color: transparent;
}

.nav_item {
    margin: 0 12px;
}

.nav_link {
    color: var(--secondary-color);
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: 0.3s ease-in-out;
}

.nav_link:hover {
    color: var(--primary-color);
}

.nav_link.active_nav {
    color: var(--primary-color);
}

.get_a_quote_btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    text-transform: capitalize;
    background-color: var(--secondary-color);
    color: #fff;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    padding: 14px 30px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.get_a_quote_btn:hover {
    color: #fff;
    background-color: var(--primary-color);
}

.humberger_menu {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.humberger_menu i {
    font-size: 30px;
}

.humberger_menu .fa-times {
    color: #fff;
}

@media screen and (max-width: 992px) {
    .nav_menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 0;
        height: 100vh;
        width: 100vw;
        background-color: var(--secondary-color);
        z-index: 1;
        transform: scaleX(0);
        transform-origin: left;
        transition: all 0.3s ease;
        line-height: 2.5;
    }

    .nav_link {
        color: #fff;
        font-size: 25px;
    }

    .nav_menu.active {
        transform: scaleX(1);
    }
}

.footer_container {
    background-color: #060f1e;
    padding: 70px 0;
}

.single-footer-widget {
    margin-bottom: 30px;
}

.single-footer-widget > h3 {
    font-size: 25px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: 500;
}

.single-footer-widget > p {
    color: #fff;
    margin-bottom: 0;
}

.footer-social {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 20px;
    display: flex;
}

.footer-social > .social_icon {
    margin-right: 10px;
    list-style-type: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
}

.single-footer-widget .footer-social > .social_icon > .fab {
    font-size: 20px;
}

/* .instragam_img_div {
    width: 80px;
    height: 80px;
} */

.instragam_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.footer-quick-links {
    padding-left: 0;
    margin-bottom: 0;
}

.footer-quick-links > li {
    list-style-type: none;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
}

.footer-quick-links > li > a {
    color: #fff;
    text-decoration: none;
}

.footer-info-contact {
    position: relative;
    padding-left: 40px;
    margin-bottom: 16px;
}

.footer-info-contact > i {
    position: absolute;
    left: 0;
    font-size: 25px;
    color: #207ef7;
}

.footer-info-contact > h3 {
    margin-bottom: 5px;
    font-size: 20px;
    font-size: 25px;
    color: #fff;
    font-weight: 500;
}

.footer-info-contact > span {
    font-size: 15px;
    color: #fff;
}

.footer-info-contact > span > a {
    text-decoration: none;
    font-size: 15px;
    color: #fff;
}

.top_banner {
    background-color: var(--secondary-color);
    display: flex;
    justify-content: space-between;
    padding: 22px 0;
}

.contact_details {
    display: flex;
}

.contact_details_items {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.contact_details_icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
}

.contact_details_text {
    color: #fff;
    font-size: 14px;
}

.social_icons_signin {
    display: flex;
    align-items: center;
}

.social_links {
    display: flex;
}

.social_icon {
    height: 25px;
    width: 25px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background: #fff;
    transition: 0.3s ease-in-out;
}

.social_icon > .fab {
    font-size: 12px;
}

/* facebook icon */
.social_icon:nth-child(1) {
    color: #3878f8;
}

.social_icon:hover:nth-child(1) {
    background-color: #3878f8;
    color: #fff;
}

/* twitter icon */
.social_icon:nth-child(2) {
    color: #1da1f2;
}

.social_icon:hover:nth-child(2) {
    background-color: #1da1f2;
    color: #fff;
}

/* pinterest icon */
.social_icon:nth-child(3) {
    color: #e60023;
}

.social_icon:hover:nth-child(3) {
    background-color: #e60023;
    color: #fff;
}

/* pinterest icon */
.social_icon:nth-child(4) {
    color: #dd2a7b;
}

.social_icon:hover:nth-child(4) {
    background-color: #dd2a7b;
    color: #fff;
}

.signin_signup button {
    background: transparent;
    border: none;
    color: #fff;
    font-weight: 500;
}

.signin_signup > a {
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
}

.signin_signup > a:nth-child(1) {
    margin-left: 10px;
}

.signup-btn {
    padding: 7px 10px;
    background-color: #fff;
    color: var(--secondary-color) !important;
    border-radius: 9px;
    font-weight: 500;
    font-size: 16px;
    transition: 0.3s ease-in-out;
}

.signup-btn.button {
    background-color: #fff;
    color: var(--secondary-color) !important;
    border-radius: 9px;
    font-weight: 500;
    font-size: 16px;
    transition: 0.3s ease-in-out;
}

.signup-btn:hover {
    color: #fff !important;
    background-color: var(--primary-color);
}

@media screen and (max-width: 992px) {
    .top_banner {
        display: none;
    }
}

#common_section {
    align-items: center;
    padding: 100px 0;
    padding-top: 50px;
}

.common_section > .common_img > img {
    width: 100%;
    height: auto;
}

.common_content > h4 {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
}

.common_content > h1 {
    font-size: clamp(1rem, 4vw, 2rem);
    font-weight: 700;
}

.common_content > p {
    margin: 10px 0 25px 0;
    color: #7b7c93;
}

/* @media screen and (max-width: 768px) {
  .common_content {
    display: none !important;
  }
} */

.Common_container {
    background-color: #fff;
}

.about_items>div {
    margin-bottom: 15px;
}

.about_item {
    display: flex;
    align-items: center;
    padding: 25px 15px;
    background-color: #f0f3fd;
    border-radius: 5px;
    transition: all 0.5s ease;
    cursor: default;
}

.about_item:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;

}

.about_item>img {
    width: 28px;
    margin-right: 10px;
}

.about_item>h5 {
    margin: 0;
}

.banner {
  background-image: url(/static/media/banner-bg.f506bfae.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  overflow: hidden;
  position: relative;
  margin-top: -80px;
}

.banner_content {
  margin: 230px 0 150px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.banner_img_div {
  position: relative;
}

.banner_img {
  position: absolute;
  top: -230px;
  right: 0;
  text-align: right;
  border-radius: 50%;
  width: 100%;
}

.banner h1 {
  font-size: clamp(1.5rem, 6vw, 3rem);
  font-weight: 700;
}

.banner p {
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
}

.filtering {
  padding: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.filter_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #5c5c5c;
  cursor: pointer;
}

.filter_item p {
  margin-bottom: 0;
}

.filter_item i {
  margin-right: 15px;
}

.filter_wrapper {
  background-color: #07092b;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.filter_wrapper input {
  padding: 10px 20px;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  height: 40px;
  color: #fff;
}

.filter_wrapper:hover {
  background-color: var(--primary-color);
}

.filter_wrapper input::placeholder {
  color: #fff;
}

.make_an_appointment {
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: #fff !important;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  padding: 14px 30px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  a {
    color: #fff !important;
    text-decoration: none !important;
  }
}

.make_an_appointment:hover {
  color: #fff;
  background-color: var(--secondary-color);
}

@media screen and (max-width: 992px) {
  .banner_img {
    display: none;
  }

  .banner {
    background-position: center left;
  }
}

@media screen and (max-width: 768px) {
  .filter_items {
    flex-direction: column;
  }

  .filter_items > .filter_item {
    margin: 5px 0;
  }
}

.banner_container {
    padding-top: 100px;
    background-color: #fff;

}

.banner_wrapper {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 2px solid #bbcafc57;
    transition: all 0.5s ease;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 30px 20px;
}

.banner_wrapper:hover {
    border: 2px solid #bbcafc;
    transform: translateY(-10px);
}

.banner_wrapper>.img_div {
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px dashed var(--primary-color);
    transition: all 0.5s ease;
    background-color: #6c9efb46;
    margin-bottom: 20px;
}

.banner_wrapper:hover>.img_div {
    background-color: #6c9efba2;

}

.banner_wrapper>h4 {
    font-size: 20px;
}

.banner_wrapper>.img_div>img {
    width: 40px;
    color: var(--primary-color);
}

.service_img {
    background-image: url(/static/media/patient-2.7821350c.jpeg);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.service_container {
    padding: 100px 0;
}

.service_header > h1 {
    font-size: clamp(2rem, 6vw, 3rem);
}

.service_card {
    background: url(/static/media/patient-2.7821350c.jpeg);
    text-align: center;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    transition: 0.5s;
    margin-bottom: 30px;
    position: relative;
}

.hypertension {
    background: url(/static/media/patient-4.09f67f78.jpeg);
    text-align: center;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    transition: 0.5s;
    margin-bottom: 30px;
    position: relative;
}

.type2diabetes {
    background: url(/static/media/patient-5.181c9fa5.jpeg);
    text-align: center;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    transition: 0.5s;
    margin-bottom: 30px;
    position: relative;
}

.service_card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 1;
    z-index: -1;
    transition: 0.5s;
    border-radius: 10px;
}

.service_card:hover {
    transform: translateY(-10px);
}

.service_card:hover::before {
    background-color: #00000083;
}

.service_card > .service_icon {
    display: inline-block;
    height: 80px;
    width: 80px;
    line-height: 90px;
    background-color: #e1e7fb;
    border: 1px solid #207ef7;
    border-radius: 50px;
    transition: 0.5s;
    margin-bottom: 25px;
}

.service_card > .service_icon i {
    color: #6c9dfb;
    transition: 0.5s;
    display: inline-block;
    font-size: 30px;
    height: 60px;
    width: 60px;
    line-height: 60px;
    background-color: #eaf7fd;
    color: #207ef7;
    border-radius: 30px;
}

.service_card > h2 {
    font-size: 25px;
    font-weight: 700;
    transition: 0.5s;
    cursor: pointer;
}

.service_card > p {
    color: #6d6d6d;
    transition: 0.5s;
    cursor: default;
}

.service_card > span {
    text-decoration: none;
    padding: 7px 10px;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 9px;
    font-weight: 500;
    font-size: 16px;
    transition: 0.5s;
    cursor: pointer;
}

.service_card > span:hover {
    background-color: #fff;
    color: var(--primary-color);
}

.service_card:hover h2,
.service_card:hover p {
    color: #fff;
}

.service_card img {
    height: 400px;
    object-fit: cover;
    border-radius: 50%;
    border-radius: 50%;
    margin-bottom: 24px;
}

#service_header_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service_header {
  text-align: center;
  color: #fff;
  margin-bottom: 40px;
  max-width: 650px;
}

.service_header h5 {
  color: var(--primary-color);
}

.solution_container {
    background-color: #fff;
}

.solutions_features {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 25px;
    margin-left: -10px;
    margin-right: -10px;
}

.solutions_features li {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.solutions_features li span {
    display: block;
    background-color: #f2f6fe;
    border-radius: 5px;
    padding: 20px 20px 20px 60px;
    z-index: 1;
    position: relative;
    transition: 0.5s;
    color: #0f123b;
    width: 90%;
    font-weight: 500;
    font-size: 18px;
}

.solutions_features li span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    border-radius: 5px;
    background-color: #207ef7;
    z-index: -1;
    transition: 0.5s;
}

.solutions_features li span::after {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    background-color: #2a2b7c;
    left: 30px;
    top: 22px;
    border-radius: 2px;
}

.common_container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: .77;
}


#common_header_items {
  height: 100%;
}

.common_header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.common_header h1 {
  font-size: clamp(1.5rem, 6vw, 3rem);
  font-weight: 700;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .common_img_div {
    display: none !important;
  }
}

.about_container {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    background-attachment: fixed;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/services-bg.bfd3cdfb.jpg);
}

.not_found {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
}

.notfound-img {
    width: 50%;
}

.not_found button a {
    text-decoration: none;
    color: #fff;
}

.contact_icon {
  display: inline-block;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7fafd;
  border-radius: 50%;
  font-size: 30px;
  color: #207ef7;
  transition: .5s;
  margin-bottom: 15px;
  transition: all 0.5s ease-in-out;
}

.contact_card:hover .contact_icon {
  background-color: var(--primary-color);
  color: #fff;
}

.contact_card h4 {
  margin-bottom: 10px;
  font-size: 23px;
  font-weight: 700;
}

.contact_card span,
.contact_card p {
  margin-bottom: 0;
  color: #7b7c93;
}

.contact_card span a {
  display: inline-block;
  color: #7b7c93;
  text-decoration: none;
}

.contact_card>div {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: all 0.5s ease-in-out;
}

.contact_card>div:hover {
  transform: translateY(-10px);
}

.contact-form {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.contact-form form .form-control {
  background-color: #f3f3f3;
  border: none;
  height: 60px;
  border: 1px solid #f3f3f3;
  transition: .5s;
  font-size: 16px;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.contact-form form textarea.form-control {
  height: auto;
  padding-top: 15px;
}

.contact-form form .form-control {
  background-color: #f3f3f3 !important;
  border: none;
  height: 60px;
  border: 1px solid #f3f3f3;
  transition: .5s;
  font-size: 16px;
}

.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  text-transform: capitalize;
  background-color: #207ef7;
  color: #fff;
  transition: .5s;
  border-radius: 30px;
  opacity: 1;
  font-weight: 400;
  font-size: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-bottom: 50px;
}

.default-btn:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

.contact_container {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  background-attachment: fixed;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(/static/media/appointment-bg.055b232a.jpg);
}

.services_container {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    background-attachment: fixed;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/patient-3.63f777f9.jpeg);
}

.services_section {
    background-color: #f4f6fe;
}

.services_container {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    background-attachment: fixed;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/patient-3.63f777f9.jpeg);
}

.services_section {
    background-color: #f4f6fe;
}

