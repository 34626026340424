.banner {
  background-image: url(../../images/banner-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  overflow: hidden;
  position: relative;
  margin-top: -80px;
}

.banner_content {
  margin: 230px 0 150px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.banner_img_div {
  position: relative;
}

.banner_img {
  position: absolute;
  top: -230px;
  right: 0;
  text-align: right;
  border-radius: 50%;
  width: 100%;
}

.banner h1 {
  font-size: clamp(1.5rem, 6vw, 3rem);
  font-weight: 700;
}

.banner p {
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
}

.filtering {
  padding: 10px 25px;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.filter_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #5c5c5c;
  cursor: pointer;
}

.filter_item p {
  margin-bottom: 0;
}

.filter_item i {
  margin-right: 15px;
}

.filter_wrapper {
  background-color: #07092b;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.filter_wrapper input {
  padding: 10px 20px;
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  height: 40px;
  color: #fff;
}

.filter_wrapper:hover {
  background-color: var(--primary-color);
}

.filter_wrapper input::placeholder {
  color: #fff;
}

.make_an_appointment {
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
  text-transform: capitalize;
  background-color: var(--primary-color);
  color: #fff !important;
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
  padding: 14px 30px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  a {
    color: #fff !important;
    text-decoration: none !important;
  }
}

.make_an_appointment:hover {
  color: #fff;
  background-color: var(--secondary-color);
}

@media screen and (max-width: 992px) {
  .banner_img {
    display: none;
  }

  .banner {
    background-position: center left;
  }
}

@media screen and (max-width: 768px) {
  .filter_items {
    flex-direction: column;
  }

  .filter_items > .filter_item {
    margin: 5px 0;
  }
}
